import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import clsx from 'clsx'

interface MarkdownRendererProps {
    content: string
    className?: string
}

export function MarkdownRenderer(props: MarkdownRendererProps) {
    const { content, className } = props
    return (
        <article className={clsx('prose', className)}>
            <ReactMarkdown children={content} />
        </article>
    )
}

import { includes } from 'lodash'

import {
    ActorOverviewFragment,
    AppointmentOverviewFragment,
    ManagerNotificationMethod,
    ActorDelayConfigsFragment,
} from '@docpace/shared-graphql/fragments'
import { 
    getAppointmentWaitForExamSeconds,
    getAppointmentWaitForIntakeSeconds,
    getAppointmentTimeInReception,
} from '@docpace/shared-ts-utils'

import {
    AppointmentStatus,
} from '../base/AppointmentStatus'
import { DelayTimestampsAppointment } from '../base/Appointment'

export interface AppointmentDelayConfigProps {
    notifyTimeInReceptionDelays?: boolean
    notifyTimeInReceptionDelaysMethod?: ManagerNotificationMethod
    notifyTimeInReceptionDelaysAfterMinutes?: number
    ignoreTimeInReceptionEarlyCheckin?: boolean
    notifyWaitForIntakeDelays?: boolean
    notifyWaitForIntakeDelaysMethod?: ManagerNotificationMethod
    notifyWaitForIntakeDelaysAfterMinutes?: number
    notifyWaitForDoctorDelays?: boolean
    notifyWaitForDoctorDelaysMethod?: ManagerNotificationMethod
    notifyWaitForDoctorDelaysAfterMinutes?: number
}

export function isAppointmentAfterTimeInReceptionDelayNotificationThreshold(
    appointment: DelayTimestampsAppointment,
    showAfterMinutes: number | null | undefined,
    ignoreTimeInReceptionEarlyCheckin: boolean
): boolean {
    const timeInReception = getAppointmentTimeInReception(
        appointment,
        true,
        ignoreTimeInReceptionEarlyCheckin
    )
    const isInCorrectState = includes(
        [
            AppointmentStatus.CHECKED_IN,
            AppointmentStatus.WAIT_FOR_INTAKE,
            AppointmentStatus.CHECKING_IN,
        ],
        appointment?.status
    )
    return timeInReception && isInCorrectState && showAfterMinutes
        ? timeInReception?.asMinutes() >= showAfterMinutes
        : false
}

export function isAppointmentAfterIntakeDelayNotificationThreshold(
    appointment: DelayTimestampsAppointment,
    showAfterMinutes: number | null | undefined
): boolean {
    const waitForIntakeDelay = getAppointmentWaitForIntakeSeconds(
        appointment,
        true
    )
    const isInCorrectState = includes(
        [AppointmentStatus.CHECKED_IN, AppointmentStatus.WAIT_FOR_INTAKE],
        appointment?.status
    )
    return waitForIntakeDelay && isInCorrectState && showAfterMinutes
        ? (waitForIntakeDelay/60) >= showAfterMinutes
        : false
}

export function isAppointmentAfterWaitForExamDelayNotificationThreshold(
    appointment: DelayTimestampsAppointment,
    showAfterMinutes: number | null | undefined
): boolean {
    const isInCorrectState = includes(
        [AppointmentStatus.WAIT_FOR_DOCTOR],
        appointment?.status
    )
    const waitForExamDelay = getAppointmentWaitForExamSeconds(
        appointment,
        true
    )
    return (waitForExamDelay && isInCorrectState && showAfterMinutes)
        ? (waitForExamDelay/60) >= showAfterMinutes
        : false
}

export function makeAppointmentDelayClassName(
    appointment: DelayTimestampsAppointment,
    delayConfigs: AppointmentDelayConfigProps
): string | null {
    const {
        notifyTimeInReceptionDelays,
        notifyTimeInReceptionDelaysMethod,
        notifyTimeInReceptionDelaysAfterMinutes,
        ignoreTimeInReceptionEarlyCheckin,
        notifyWaitForIntakeDelays,
        notifyWaitForIntakeDelaysMethod,
        notifyWaitForIntakeDelaysAfterMinutes,
        notifyWaitForDoctorDelays,
        notifyWaitForDoctorDelaysMethod,
        notifyWaitForDoctorDelaysAfterMinutes,
    } = delayConfigs ?? {}

    if (
        notifyTimeInReceptionDelays &&
        notifyTimeInReceptionDelaysMethod === ManagerNotificationMethod.Flash &&
        isAppointmentAfterTimeInReceptionDelayNotificationThreshold(
            appointment,
            notifyTimeInReceptionDelaysAfterMinutes,
            ignoreTimeInReceptionEarlyCheckin ?? false
        )
    ) {
        return 'time-in-reception-delay-row-flash'
    } else if (
        notifyWaitForIntakeDelays &&
        notifyWaitForIntakeDelaysMethod === ManagerNotificationMethod.Flash &&
        isAppointmentAfterIntakeDelayNotificationThreshold(
            appointment,
            notifyWaitForIntakeDelaysAfterMinutes
        )
    ) {
        return 'wait-for-intake-delay-row-flash'
    } else if (
        notifyWaitForDoctorDelays &&
        notifyWaitForDoctorDelaysMethod === ManagerNotificationMethod.Flash &&
        isAppointmentAfterWaitForExamDelayNotificationThreshold(
            appointment,
            notifyWaitForDoctorDelaysAfterMinutes
        )
    ) {
        return 'wait-for-exam-delay-row-flash'
    }
    return null
}

export function makeActorDelayConfigs(actor: ActorDelayConfigsFragment){
    return {
        notifyTimeInReceptionDelays: actor?.managerNotifyTimeInReceptionDelays,
        notifyTimeInReceptionDelaysMethod:
            actor?.managerNotifyTimeInReceptionDelaysMethod,
        notifyTimeInReceptionDelaysAfterMinutes:
            actor?.managerNotifyTimeInReceptionDelaysAfterMinutes,
        ignoreTimeInReceptionEarlyCheckin:
            actor?.managerIgnoreTimeInReceptionEarlyCheckIn,
        notifyWaitForIntakeDelays: actor?.managerNotifyWaitForIntakeDelays,
        notifyWaitForIntakeDelaysMethod:
            actor?.managerNotifyWaitForIntakeDelaysMethod,
        notifyWaitForIntakeDelaysAfterMinutes:
            actor?.managerNotifyWaitForIntakeDelaysAfterMinutes,
        notifyWaitForDoctorDelays: actor?.managerNotifyWaitForDoctorDelays,
        notifyWaitForDoctorDelaysMethod:
            actor?.managerNotifyWaitForDoctorDelaysMethod,
        notifyWaitForDoctorDelaysAfterMinutes:
            actor?.managerNotifyWaitForDoctorDelaysAfterMinutes,
    }
}
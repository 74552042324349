import {
    AppointmentOverviewFragment,
} from '@docpace/shared-graphql/fragments'
import { isAfter, subHours } from 'date-fns'
import { get, includes, indexOf, startCase, lowerCase } from 'lodash'

export enum AppointmentStatus {
    SCHEDULED = 'SCHEDULED',
    CHECKING_IN = 'CHECKING_IN',
    CHECKED_IN = 'CHECKED_IN',
    WAIT_FOR_INTAKE = 'WAIT_FOR_INTAKE',
    INTAKE = 'INTAKE',
    WAIT_FOR_DOCTOR = 'WAIT_FOR_DOCTOR',
    EXAM = 'EXAM',
    WAIT_FOR_CHECKOUT = 'WAIT_FOR_CHECKOUT',
    CHECKING_OUT = 'CHECKING_OUT',
    CHECKED_OUT = 'CHECKED_OUT',
    CANCELED = 'CANCELED',
    LEFT_WITHOUT_BEING_SEEN = 'LEFT_WITHOUT_BEING_SEEN',
    NO_SHOW = 'NO_SHOW',
    UNKNOWN = 'UNKNOWN',
}

export enum CalculatedAppointmentStatus {
    Scheduled = 'Scheduled',
    CheckingIn = 'CheckingIn',
    CheckedIn = 'CheckedIn',
    WaitForIntake = 'WaitForIntake',
    Intake = 'Intake',
    WaitForDoctor = 'WaitForDoctor',
    Exam = 'Exam',
    WaitForCheckout = 'WaitForCheckout',
    CheckingOut = 'CheckingOut',
    CheckedOut = 'CheckedOut',
    Canceled = 'Canceled',
    LeftWithoutBeingSeen = 'LeftWithoutBeingSeen',
    NoShow = 'NoShow',
    Rescheduled = 'Rescheduled',
    Arrived = 'Arrived',
    Done = 'Done',
    Unknown = 'Unknown'
}

export const CalculatedAppointmentStatusOrderOfImportance: CalculatedAppointmentStatus[] =
    [
        CalculatedAppointmentStatus.Scheduled,
        CalculatedAppointmentStatus.Arrived,
        CalculatedAppointmentStatus.CheckingIn,
        CalculatedAppointmentStatus.CheckedIn,
        CalculatedAppointmentStatus.WaitForIntake,
        CalculatedAppointmentStatus.Intake,
        CalculatedAppointmentStatus.WaitForDoctor,
        CalculatedAppointmentStatus.Exam,
        CalculatedAppointmentStatus.WaitForCheckout,
        CalculatedAppointmentStatus.CheckingOut,
        CalculatedAppointmentStatus.CheckedOut,
        CalculatedAppointmentStatus.Rescheduled,
        CalculatedAppointmentStatus.Canceled,
        CalculatedAppointmentStatus.LeftWithoutBeingSeen,
        CalculatedAppointmentStatus.NoShow,
        CalculatedAppointmentStatus.Unknown,
    ]

export function appointmentToCalculatedAppointmentStatus(
    appt : { 
        status: string
        pausedAt?: any | null
        patientManualCheckIn?: any | null
        rescheduledAppointmentId?: string | null | undefined
    }
): CalculatedAppointmentStatus {
    if (
        appt?.status === AppointmentStatus.SCHEDULED &&
        !!appt?.patientManualCheckIn
    ) {
        return CalculatedAppointmentStatus.Arrived
    }

    if (
        appt?.status === AppointmentStatus.CANCELED &&
        !!appt?.rescheduledAppointmentId
    ) {
        return CalculatedAppointmentStatus.Rescheduled
    }
    return (
        CalculatedAppointmentStatus[
            startCase(lowerCase(appt?.status))?.split(' ').join('')
        ] ?? CalculatedAppointmentStatus.Unknown
    )
}

export function appointmentToStatusString(
    appt : { 
        status: string, 
        pausedAt?: any | null
        patientManualCheckIn?: any | null
        rescheduledAppointmentId?: string | null | undefined
    }
): string {
    return calculatedAppointmentStatusToString(
        appointmentToCalculatedAppointmentStatus(appt)
    )
}

export function calculatedAppointmentStatusToStrings(
    calculatedStatus: CalculatedAppointmentStatus
): string[] {
    switch (calculatedStatus) {
        case CalculatedAppointmentStatus.Scheduled:
            return ['Pending']
        case CalculatedAppointmentStatus.Arrived:
            return ['Arrived']
        case CalculatedAppointmentStatus.CheckingIn:
            return ['Checking In']
        case CalculatedAppointmentStatus.CheckedIn:
            return ['Checked In']
        case CalculatedAppointmentStatus.WaitForIntake:
            return ['Ready for', 'Staff']
        case CalculatedAppointmentStatus.Intake:
            return ['Intake']
        case CalculatedAppointmentStatus.WaitForDoctor:
            return ['Ready for', 'Provider']
        case CalculatedAppointmentStatus.Exam:
            return ['In Exam']
        case CalculatedAppointmentStatus.WaitForCheckout:
            return ['Ready for', 'Checkout']
        case CalculatedAppointmentStatus.CheckingOut:
            return ['Checking Out']
        case CalculatedAppointmentStatus.CheckedOut:
            return ['Done']
        case CalculatedAppointmentStatus.Canceled:
            return ['Canceled']
        case CalculatedAppointmentStatus.LeftWithoutBeingSeen:
            return ['Left', 'Not Seen']
        case CalculatedAppointmentStatus.Rescheduled:
            return ['Rescheduled']
        case CalculatedAppointmentStatus.NoShow:
            return ['No Show']
        default:
            return ['Unknown']
    }
}

export function calculatedAppointmentStatusToString(
    calculatedStatus: CalculatedAppointmentStatus
): string {
    return calculatedAppointmentStatusToStrings(calculatedStatus).join(' ')
}

export function compareAppointmentStatus(
    a: CalculatedAppointmentStatus,
    b: CalculatedAppointmentStatus
) {
    const aIdx = CalculatedAppointmentStatusOrderOfImportance.indexOf(a)
    const bIdx = CalculatedAppointmentStatusOrderOfImportance.indexOf(b)

    return aIdx - bIdx
}

export const CompletedAppointmentStatuses = [AppointmentStatus.CHECKED_OUT]

export const NotSeenAppointmentStatuses = [
    AppointmentStatus.CANCELED,
    AppointmentStatus.LEFT_WITHOUT_BEING_SEEN,
    AppointmentStatus.NO_SHOW,
]

export const NotActiveAppointmentStatuses = [
    AppointmentStatus.WAIT_FOR_CHECKOUT,
    AppointmentStatus.CHECKING_OUT,
    AppointmentStatus.CHECKED_OUT,
    AppointmentStatus.CANCELED,
    AppointmentStatus.INTAKE,
    AppointmentStatus.LEFT_WITHOUT_BEING_SEEN,
    AppointmentStatus.NO_SHOW,
]

export const NotSeenCalculatedAppointmentStatuses = [
    CalculatedAppointmentStatus.Canceled,
    CalculatedAppointmentStatus.Rescheduled,
    CalculatedAppointmentStatus.LeftWithoutBeingSeen,
    CalculatedAppointmentStatus.NoShow,
    CalculatedAppointmentStatus.Unknown,
]

export const NotStartedAppointmentStatuses = [
    CalculatedAppointmentStatus.Scheduled,
    CalculatedAppointmentStatus.CheckingIn,
    CalculatedAppointmentStatus.WaitForIntake,
    CalculatedAppointmentStatus.Intake,
    CalculatedAppointmentStatus.WaitForDoctor,
]

export const DashboardViewStatuses = [
    AppointmentStatus.SCHEDULED, 
    AppointmentStatus.CHECKING_IN, 
    AppointmentStatus.CHECKED_IN, 
    AppointmentStatus.WAIT_FOR_INTAKE, 
    AppointmentStatus.INTAKE, 
    AppointmentStatus.WAIT_FOR_DOCTOR, 
    AppointmentStatus.EXAM, 
    AppointmentStatus.WAIT_FOR_CHECKOUT, 
    AppointmentStatus.CHECKING_OUT, 
    AppointmentStatus.CHECKED_OUT
]

export const NotStartedCalculatedAppointmentStatuses = [
    CalculatedAppointmentStatus.Scheduled,
    CalculatedAppointmentStatus.CheckingIn,
    CalculatedAppointmentStatus.WaitForIntake,
    CalculatedAppointmentStatus.Intake,
    CalculatedAppointmentStatus.WaitForDoctor,
]

export function isAppointmentInNotStartedState(
    appt : { 
        status: string, 
        pausedAt?: any | null
        patientManualCheckIn?: any | null
        rescheduledAppointmentId?: string | null | undefined
    }
): boolean {
    const calculatedAppointmentStatus = appointmentToCalculatedAppointmentStatus(appt)
    return includes(
        NotStartedAppointmentStatuses,
        calculatedAppointmentStatus
    )
}

export function shouldAppointmentAppearInDashboardByDefault(
    appt : { 
        status: string, 
    }
): boolean {
    return includes(DashboardViewStatuses, appt?.status)
}

export function wasAppointmentCanceledWithinHours(
    appt : { 
        status: string, 
        timeCancelled: string
    },
    hours: number
){
    return appt?.status === AppointmentStatus.CANCELED 
    && isAfter(appt?.timeCancelled, subHours(new Date(), hours))
}

export function isAppointmentActualEndTimeIndeterminate(
    appt : { 
        status: string, 
        pausedAt?: any | null
        patientManualCheckIn?: any | null
        rescheduledAppointmentId?: string | null | undefined
    }
): boolean {
    const status = appointmentToCalculatedAppointmentStatus(appt)
    if (appt.pausedAt) return false
    if (
        includes(
            [
                CalculatedAppointmentStatus.Scheduled,
                CalculatedAppointmentStatus.Arrived,
                CalculatedAppointmentStatus.CheckingIn,
                CalculatedAppointmentStatus.CheckedIn,
                CalculatedAppointmentStatus.WaitForIntake,
                CalculatedAppointmentStatus.Intake,
                CalculatedAppointmentStatus.WaitForDoctor,
                CalculatedAppointmentStatus.Exam,
                CalculatedAppointmentStatus.WaitForCheckout,
                CalculatedAppointmentStatus.CheckingOut,
            ],
            status
        )
    )
        return true

    return false
}

export function isAppointmentActualEndTimeDeterminate(
    appt : { 
        status: string, 
        pausedAt?: any | null
        patientManualCheckIn?: any | null
        rescheduledAppointmentId?: string | null | undefined
    }
): boolean {
    const status = appointmentToCalculatedAppointmentStatus(appt)
    if (appt.pausedAt) return false
    if (
        includes(
            [
                CalculatedAppointmentStatus.WaitForCheckout,
                CalculatedAppointmentStatus.CheckingOut,
                CalculatedAppointmentStatus.CheckedOut,
            ],
            status
        )
    )
        return true

    return false
}

export function isAppointmentPendingOrCheckedIn(
    appointment: AppointmentOverviewFragment
): boolean {
    switch (appointment?.status) {
        case AppointmentStatus.SCHEDULED:
        case AppointmentStatus.CHECKING_IN:
        case AppointmentStatus.CHECKED_IN: {
            return true
        }
        default:
            return false
    }
}

export function getTimelineStepIndex(
    calculatedStatus: CalculatedAppointmentStatus
): number {
    switch (calculatedStatus) {
        case CalculatedAppointmentStatus.Scheduled:
            return -1
        case CalculatedAppointmentStatus.Arrived:
            return 1
        case CalculatedAppointmentStatus.CheckingIn:
            return 0
        case CalculatedAppointmentStatus.CheckedIn:
            return 1
        case CalculatedAppointmentStatus.WaitForIntake:
            return 1
        case CalculatedAppointmentStatus.Intake:
            return 2
        case CalculatedAppointmentStatus.WaitForDoctor:
            return 3
        case CalculatedAppointmentStatus.Exam:
            return 4
        case CalculatedAppointmentStatus.WaitForCheckout:
            return 5
        case CalculatedAppointmentStatus.CheckingOut:
            return 6
        case CalculatedAppointmentStatus.CheckedOut:
            return 7
        case CalculatedAppointmentStatus.Canceled:
            return -1
        case CalculatedAppointmentStatus.LeftWithoutBeingSeen:
            return -1
        case CalculatedAppointmentStatus.Rescheduled:
            return -1
        case CalculatedAppointmentStatus.NoShow:
            return -1
        default:
            return -1
    }
}

import React, { FC, useEffect } from 'react'
import * as yup from 'yup'
import { useAtom, WritableAtom } from 'jotai'
import { atomWithReset, useResetAtom } from 'jotai/utils'

import { UseReactHookFormProps } from '@docpace/shared-react-hooks/useReactHookForm'
import { useDialogAtom } from '@docpace/shared-react-hooks/useDialogAtom'

export interface ContactSupportFormProps
    extends Omit<UseReactHookFormProps, 'schema' | 'defaultValues'> {
    onCancel?: () => any
}

export interface ContactSupportFormSchema {
    subject?: string
    messageBody: string
    email: string
}

type ContactSupportFormSchemaPartial = Partial<ContactSupportFormSchema> | null | undefined

export const ContactSupportFormYupSchema = {
    subject: yup.string().notRequired().nullable(),
    messageBody: yup.string().required(),
    email: yup.string().required()
}

export const ContactSupportFormAtom = atomWithReset<ContactSupportFormSchemaPartial>(null)

export const useContactSupportFormSchema = (
    _atom: WritableAtom<ContactSupportFormSchemaPartial, any[], any> = ContactSupportFormAtom
) => {
    const [ defaultValues, setDefaultValues ] = useAtom(_atom)
    const { isOpen, doOpen, closeDialog } = useDialogAtom(_atom)
    const resetDefaultValues = useResetAtom(_atom)

    return {
        defaultValues,
        setDefaultValues,
        resetDefaultValues,
        openInDialog: (defaultValues?: ContactSupportFormSchemaPartial)=>{
            doOpen(typeof defaultValues !== 'undefined' ? defaultValues : null)
        },
        isOpen,
        closeDialog
    }
}
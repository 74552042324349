export const practiceOverviewTooltipText = `Central Dashboard Summary: 
- Comprehensive Data Summary
- Areas Needing Attention
- Monthly Snapshot for Internal Use` 
export const workflowQualityTooltipText = `Workflow Insights: 
- Encounter Workflows
- Timestamps and Data Quality
- Open Encounters Tracking
- Workflow Compliance` 
export const growthTooltipText = `Growth Metrics Summary: 
- Clinic Growth Insights
- Schedule and Outcome Reporting
- Patient Demographics and Payor Mix
- Clinic Hours and Visit Tracking
- Daily Patient Volumes Summar` 
export const appointmentDurationsTooltipText = `Duration & Wait Time Analysis: 
- Appointment Duration Trends
- Calendar View for Quick Reference
- Wait Time Analysis by Day ` 
export const workingHoursTooltipText = `Productivity & Hours Overview: 
- Completed Visits Analysis
- Throughput and Productivity Rates
- Provider Schedules and Cancellations
- Working Hours Summary` 
export const patientBehaviorTooltipText = `Behavioral Insights: 
- No Shows and Cancellations Monitoring
- Chronic No Shows and Late Patients Tracking
- Patient Arrival Trends Analysis
- Adherence ` 
export const templateViewTooltipText = `Scheduling Efficiency: 
- Schedule Template Overview
- Appointment Density Tracking
- Slot Utilization and Open Slots Visibility` 
export const checkoutTableTooltipText = `Real-Time Tracking:
- Today's Appointment Data
- Active Appointment Tracking
- Future Outcome Estimations` 
export const patientFlowReportTooltipText = `Snapshot Diagnostic Report: 
- Scheduling Audit Overview
- Patient Flow Assessment at Interface Start
- Identifies Inefficiencies`
export const monthlyReportTooltipText = `Previous Month Overview: 
- Insights on Patient Arrival 
- Appointment Durations
- Schedule Outcomes`
import { TokenRefreshLink } from 'apollo-link-token-refresh'

import {
    refreshAccessTokenFromAPI,
    setSessionData,
    notifyNewSession,
} from '@docpace/shared-react-utils/sessionUtils'
import { isTokenValidOrUndefined } from '@docpace/shared-ts-utils'

interface MakeApolloRefreshTokenLinkInput {
    serverHostname: string
}

export const makeApolloRefreshTokenLink = ({ serverHostname } : MakeApolloRefreshTokenLinkInput) => {
    return new TokenRefreshLink({
        isTokenValidOrUndefined,
        fetchAccessToken: async ()=>refreshAccessTokenFromAPI(serverHostname),
        handleFetch: async (accessToken) => accessToken,
        handleResponse: (operation, accessTokenField) => async (response) => {
            await setSessionData(response)
            await notifyNewSession()
            return response
        },
        handleError: (err) => {
            if (
                !navigator.onLine ||
                (err instanceof TypeError &&
                    err.message === 'Network request failed')
            ) {
                console.log('Offline -> do nothing 🍵')
            } else if (window?.location?.pathname?.split('/')?.[1] === 'auth'){
                return 
            } else {
                setSessionData(null).then(() => {})
                console.log('token refresh error detected - redirecting..')
                window.location.replace('/auth')
            }
        },
    })
}
